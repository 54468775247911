import { qoursesApiInstructor } from '@/api/qourses-instructor.tsx'
import { qoursesApi } from '@/api/qourses.tsx'
import {
  advertisingIdParam,
  courseIdParam,
  meetingIdParam,
  multipassProductIdParam,
  organizationIdParam,
  tagIdParam,
} from '@/pages/public/booking/SessionParams.tsx'
import * as Sentry from '@sentry/react'
import { toast } from 'sonner'

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export async function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms))
}

export async function minDelay<T>(promise: Promise<T>, ms: number) {
  const [p] = await Promise.all([promise, sleep(ms)])

  return p
}

export enum ToastVariant {
  Info = 'info',
  Success = 'success',
  Error = 'error',
}

export function sendNotification(
  title: string,
  subtitle: string,
  variant: ToastVariant,
  duration = 3000,
) {
  if (variant === ToastVariant.Info) {
    toast.info(title, {
      description: subtitle,
      duration: duration,
    })
  }
  if (variant === ToastVariant.Success) {
    toast.success(title, {
      description: subtitle,
      duration: duration,
    })
  }
  if (variant === ToastVariant.Error) {
    toast.error(title, {
      description: subtitle,
      duration: duration,
    })
  }
}

export function removePointerEventsFromBody() {
  document.body.style.pointerEvents = ''
}

export function sendGenericErrorNotification(error?: unknown) {
  Sentry.captureException(error)
  sendNotification(
    'Something unexpected happened',
    'Something went wrong, please try again',
    ToastVariant.Error,
  )
}

interface BookingLinkOptions {
  organizationId: string
  courseId?: string
  meetingId?: string
  tagId?: string
  multipassProductId?: string
  includeDetails?: boolean
  includeLocation?: boolean
  includeInstructor?: boolean
}

function createBookingURL(options: BookingLinkOptions): string {
  const {
    organizationId,
    courseId,
    meetingId,
    tagId,
    multipassProductId,
    includeDetails = true,
    includeLocation = true,
    includeInstructor = true,
  } = options

  const params = new URLSearchParams({ [organizationIdParam]: organizationId })

  if (courseId) params.append(courseIdParam, courseId)
  if (meetingId) params.append(meetingIdParam, meetingId)
  if (tagId) params.append(tagIdParam, tagId)
  if (multipassProductId) params.append(multipassProductIdParam, multipassProductId)

  const renderingOptions = [
    includeDetails && 'details',
    includeLocation && 'location',
    includeInstructor && 'instructor',
  ].filter(Boolean)

  if (renderingOptions.length > 0) {
    params.append(advertisingIdParam, renderingOptions.join(','))
  }

  return `${window.location.origin}/book?${params.toString()}`
}

export function copyBookingLinkToClipboard(options: BookingLinkOptions): string {
  const url = createBookingURL(options)
  navigator.clipboard.writeText(url)
  return url
}

export function createBookingLink(options: BookingLinkOptions & { newTab?: boolean }): string {
  const { newTab, ...urlOptions } = options
  const url = createBookingURL(urlOptions)

  if (newTab) {
    window.open(url, '_blank')
  }

  return url
}

export const redirectToStripeOnboarding = async (
  notificationTitle: string,
  notificationSubtitle: string,
) => {
  sendNotification(notificationTitle, notificationSubtitle, ToastVariant.Info)

  const onboarding = await minDelay(
    qoursesApi.payment.paymentControllerGetStripePaymentProfileOnboarding('false'),
    2000,
  )

  window.location.href = onboarding.url
}

export const redirectToStripeOnboardingInstructor = async (
  notificationTitle: string,
  notificationSubtitle: string,
) => {
  sendNotification(notificationTitle, notificationSubtitle, ToastVariant.Info)

  const onboarding = await minDelay(
    qoursesApiInstructor.payment.paymentControllerGetStripePaymentProfileOnboarding(),
    2000,
  )

  window.location.href = onboarding.url
}

export function getRandomEmoji() {
  const emojis = [
    '🏄',
    '🦄',
    '🐙',
    '🐬',
    '🌈',
    '🍔',
    '🍪',
    '🍭',
    '🌿',
    '🦐',
    '🎮',
    '🕹️',
    '🧁',
    '🥑',
    '🍕',
    '🚀',
    '👾',
    '🎉',
    '🔮',
    '🦩',
    '🍉',
    '🎈',
    '🧩',
    '🛹',
    '🍿',
    '🥳',
    '🐉',
    '🍜',
    '🍣',
    '🪁',
  ]
  return emojis[Math.floor(Math.random() * emojis.length)]
}
