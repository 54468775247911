/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerEntity } from '../models/CustomerEntity';
import type { InstructorEntity } from '../models/InstructorEntity';
import type { PatchProfileDto } from '../models/PatchProfileDto';
import type { PatchProfileInstructorDto } from '../models/PatchProfileInstructorDto';
import type { PostCustomerInstructorDto } from '../models/PostCustomerInstructorDto';
import type { PostDismissHelpCardDto } from '../models/PostDismissHelpCardDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProfileCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get your Customer profile
     * @returns CustomerEntity Customer has been returned
     * @throws ApiError
     */
    public profileCustomerControllerGetProfile(): CancelablePromise<CustomerEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/profile',
        });
    }
    /**
     * Change your Customer profile
     * @param requestBody
     * @returns CustomerEntity Profile has been changed
     * @throws ApiError
     */
    public profileCustomerControllerPatchProfile(
        requestBody: PatchProfileDto,
    ): CancelablePromise<CustomerEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/customer/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the Customer's Instructor
     * @returns InstructorEntity Instructor has been returned
     * @throws ApiError
     */
    public profileCustomerControllerGetInstructor(): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/profile/instructor',
            errors: {
                404: `Instructor has not been found`,
            },
        });
    }
    /**
     * Create the Customer's Instructor
     * @param requestBody
     * @returns InstructorEntity Instructor has been created
     * @throws ApiError
     */
    public profileCustomerControllerCreateInstructor(
        requestBody: PostCustomerInstructorDto,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/profile/instructor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update the Customer's Instructor
     * @param requestBody
     * @returns InstructorEntity Instructor has been updated
     * @throws ApiError
     */
    public profileCustomerControllerPatchProfileInstructor(
        requestBody: PatchProfileInstructorDto,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/customer/profile/instructor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Change a help card dismissal status
     * @param requestBody
     * @returns any Help card dismissal status has been added to your token app metadata
     * @throws ApiError
     */
    public profileCustomerControllerChangeHelpCardDismissalStatus(
        requestBody: PostDismissHelpCardDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/profile/help/dismiss',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
