import { qoursesApi } from '@/api/qourses.tsx'
import { CouponName } from '@/components/Resolvers.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import useGetCoupon, { getCouponQueryKey } from '@/hooks/coupons/useGetCoupon.tsx'
import { getCouponsQueryKey } from '@/hooks/coupons/useGetCoupons.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, minDelay, sendGenericErrorNotification, sendNotification } from '@/utils.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { ArchiveRestore, Calendar, Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function RestoreCouponModal({ couponId }: { couponId: string }) {
  const { coupon, isLoading, isError } = useGetCoupon(couponId)
  const [isRestoring, setIsRestoring] = useState(false)

  const queryClient = useQueryClient()

  const { t: translate } = useTranslation()

  const handleRestoreCoupon = async () => {
    try {
      setIsRestoring(true)
      await minDelay(qoursesApi.rebate.rebateControllerRestoreRebateCode(coupon.id), 1000)
      sendNotification(
        translate('modals.restoreCouponModal.notification.title'),
        translate('modals.restoreCouponModal.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      queryClient.invalidateQueries(getCouponsQueryKey({}))
      queryClient.invalidateQueries(getCouponQueryKey(couponId))
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
    } finally {
      setIsRestoring(false)
    }
  }

  if (isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('common.loading')}</DialogTitle>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>{translate('modals.restoreCouponModal.errorState.title')}</DialogTitle>
          <DialogDescription>
            {translate('modals.restoreCouponModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-6 sm:mt-2"></DialogTitle>
          <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
            <div className="flex flex-row items-center justify-center text-sm text-muted-foreground sm:justify-start">
              <Calendar className="mr-1 size-4 text-muted-foreground" />
              <CouponName rebateCodeId={couponId} />
            </div>
            <p>{translate('modals.restoreCouponModal.title')}</p>
          </DialogTitle>
          <DialogDescription>{translate('modals.restoreCouponModal.subtitle')}</DialogDescription>
        </DialogHeader>
      </DialogHeader>
      <Button
        variant="indigo"
        className="bezel"
        disabled={isRestoring}
        onClick={handleRestoreCoupon}
      >
        {isRestoring ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {translate('common.loading')}
          </>
        ) : (
          <>
            <ArchiveRestore className="mr-2 h-4 w-4" />
            {translate('modals.restoreCouponModal.button')}
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
