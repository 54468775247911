import { qoursesApi } from '@/api/qourses.tsx'
import { InstructorName } from '@/components/Resolvers.tsx'
import { InstructorSelection } from '@/components/entityDropdowns/InstructorSelection.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import useGetCalendars from '@/hooks/calendars/useGetCalendars.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, sendNotification } from '@/utils.tsx'
import { ChevronRight, Cog } from 'lucide-react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { CalendarEntity, InstructorEntity, PostCalendarDto } from '../../../qourses-api-client'
import privilege = PostCalendarDto.privilege

export default function BackofficeAddMeetingsToCalendarModal() {
  const { t: translate } = useTranslation()
  const apiUrl = import.meta.env.VITE_API_URL
  const [loading, setLoading] = useState(false)

  const [selectedInstructor, setSelectedInstructor] = useState<InstructorEntity | null>(null)

  const {
    calendars,
    isLoading: isLoadingCalendars,
    isError: isErrorCalendars,
    refetch,
  } = useGetCalendars()

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const handleCreateCalendar = async (
    calendarProvider: 'apple' | 'google' | 'outlook' | 'manual',
  ) => {
    setLoading(true)

    let calendar = {} as CalendarEntity

    try {
      if (selectedInstructor) {
        calendar = await qoursesApi.calendar.calendarControllerCreateCalendar(
          {
            instructorId: selectedInstructor.id,
            privilege: privilege.FULL,
          },
          'false',
        )
      } else {
        calendar = await qoursesApi.calendar.calendarControllerCreateCalendar(
          {
            privilege: privilege.FULL,
          },
          'false',
        )
      }

      const calendarUrl = `${apiUrl}/v1/public/calendar/${calendar.token}/${DateTime.now().plus({ years: 3 }).toISODate()}`

      if (calendarProvider === 'apple') {
        const webcalUrl = calendarUrl.replace('https://', 'webcal://')
        if (isMobile) {
          setTimeout(() => {
            window.open(`${webcalUrl}`, '_blank')
          })
        } else {
          setTimeout(() => {
            window.open(`${webcalUrl}`, 'noopener,noreferrer')
          })
        }
      }

      if (calendarProvider === 'google') {
        const webcalUrl = calendarUrl.replace('https://', 'webcal://')
        setTimeout(() => {
          window.open(`https://calendar.google.com/calendar/r?cid=${webcalUrl}`, '_blank')
        })
      }

      if (calendarProvider === 'outlook') {
        setTimeout(() => {
          window.open(
            `https://outlook.live.com/calendar/addcalendar?url=${calendarUrl}&name=Qourses`,
          )
        })
      }

      if (calendarProvider === 'manual') {
        navigator.clipboard.writeText(calendarUrl)
        sendNotification(
          translate('modals.backofficeAddMeetingsToCalendarModal.notifications.manualCopy.title'),
          translate(
            'modals.backofficeAddMeetingsToCalendarModal.notifications.manualCopy.subtitle',
          ),
          ToastVariant.Info,
        )
      }

      refetch()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleArchiveCalendar = async (calendarId: string) => {
    try {
      await qoursesApi.calendar.calendarControllerArchiveCalendar(calendarId, 'false')
      refetch()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="flex flex-col gap-y-2 sm:mt-2">
          <p className="mt-4">{translate('modals.backofficeAddMeetingsToCalendarModal.title')}</p>
        </DialogTitle>
        <DialogDescription>
          {translate('modals.backofficeAddMeetingsToCalendarModal.subtitle')}
        </DialogDescription>
      </DialogHeader>

      {calendars.length > 0 && (
        <div className="mb-4 relative bg-gray-50 p-2 ring-1 ring-gray-200 rounded-lg shadow-inner max-h-32 overflow-y-auto">
          {!isLoadingCalendars &&
            !isErrorCalendars &&
            calendars.map((calendar) => (
              <div
                key={calendar.id}
                className="mt-2 bg-white text-sm flex gap-x-1 p-2 border rounded-md items-center justify-between first:mt-2 last:mb-2"
              >
                <div>
                  <p>{translate('modals.backofficeAddMeetingsToCalendarModal.lastSynced')}</p>
                  <p className="font-semibold text-xs">
                    {calendar.lastUsageDay !== null
                      ? DateTime.fromISO(calendar.lastUsageDay).toLocaleString(DateTime.DATE_FULL)
                      : translate('modals.backofficeAddMeetingsToCalendarModal.syncPending')}
                  </p>
                  {calendar.instructorId && (
                    <div className="text-xs mt-1">
                      {translate(
                        'modals.backofficeAddMeetingsToCalendarModal.instructor.calendarLabel',
                      )}
                      <InstructorName instructorId={calendar.instructorId} />
                    </div>
                  )}
                </div>
                <div
                  className="cursor-pointer text-xs underline mt-1 flex items-center"
                  onClick={() => handleArchiveCalendar(calendar.id)}
                >
                  {translate('modals.backofficeAddMeetingsToCalendarModal.archiveCalendar')}
                  <ChevronRight className="ml-1 size-3" />
                </div>
              </div>
            ))}
        </div>
      )}

      <div className="relative mb-2">
        <div aria-hidden="true" className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 font-semibold text-muted-foreground">
            {translate('modals.backofficeAddMeetingsToCalendarModal.chooseCalendarTitle')}
          </span>
        </div>
      </div>

      <div>
        <div className="text-sm mb-2">
          {translate('modals.backofficeAddMeetingsToCalendarModal.instructor.label')}
          <Badge variant="indigoBezel" className="ml-2">
            Optional
          </Badge>
        </div>
        <InstructorSelection setSelectedInstructor={setSelectedInstructor} />
        <p className="text-xs mt-1">
          {translate('modals.backofficeAddMeetingsToCalendarModal.instructor.subtitle')}
        </p>
      </div>

      <Button
        variant="outline"
        className="mt-3 sm:mt-0"
        onClick={() => handleCreateCalendar('apple')}
        disabled={loading}
      >
        <img className="inline-block size-6 p-1 mr-1" src="/logos/apple.svg" alt="Apple Logo" />
        {translate('modals.backofficeAddMeetingsToCalendarModal.buttonApple')}
      </Button>
      <Button
        variant="outline"
        className="mt-3 sm:mt-0"
        onClick={() => handleCreateCalendar('google')}
        disabled={loading}
      >
        <img className="inline-block size-6 p-1 mr-1" src="/logos/google.svg" alt="Google Logo" />
        {translate('modals.backofficeAddMeetingsToCalendarModal.buttonGoogle')}
      </Button>
      <Button
        variant="outline"
        className="mt-3 mb-2 sm:mt-0 sm:mb-0"
        onClick={() => handleCreateCalendar('outlook')}
        disabled={loading}
      >
        <img
          className="inline-block size-6 p-1 mr-1"
          src="/logos/microsoft.svg"
          alt="Microsoft Logo"
        />
        {translate('modals.backofficeAddMeetingsToCalendarModal.buttonOutlook')}
      </Button>
      <div className="mt-2 border-t-2 border-dashed">
        <Button
          variant="outline"
          className="flex flex-1 w-full mt-3 sm:mt-6"
          onClick={() => handleCreateCalendar('manual')}
        >
          <Cog className="inline-block size-6 p-1 mr-1" />
          {translate('modals.backofficeAddMeetingsToCalendarModal.buttonManual')}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
