/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEntity } from '../models/CalendarEntity';
import type { PostGuestCalendarDto } from '../models/PostGuestCalendarDto';
import type { PostOrderCalendarDto } from '../models/PostOrderCalendarDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create an Order Calendar
     * @param requestBody
     * @returns CalendarEntity Create an Order Calendar
     * @throws ApiError
     */
    public calendarPublicControllerCreateOrderCalendar(
        requestBody: PostOrderCalendarDto,
    ): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/calendar/order',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create a Guest Calendar
     * @param requestBody
     * @returns CalendarEntity Create an Guest Calendar
     * @throws ApiError
     */
    public calendarPublicControllerCreateGuestCalendar(
        requestBody: PostGuestCalendarDto,
    ): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/calendar/customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a Calendar ICS
     * @param token
     * @param activeUntil
     * @returns CalendarEntity The calendar has been returned
     * @throws ApiError
     */
    public calendarPublicControllerGetCalendar(
        token: string,
        activeUntil?: string,
    ): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/calendar/{token}/{activeUntil}',
            path: {
                'token': token,
            },
            query: {
                'activeUntil': activeUntil,
            },
        });
    }
}
