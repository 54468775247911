import { motion } from 'framer-motion'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

export default function LoadingLogo() {
  const { t: translate } = useTranslation()

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.3,
          delay: 0.5,
        }}
        className="delay-100 w-full opacity-100 bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 justify-self-center"
        data-testid="loader"
      >
        <div className="max-w-max mx-auto justify-self-center">
          <main className="sm:flex">
            <LoadingIcon className="flex h-full w-full text-indigo-500" aria-hidden="true" />
          </main>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatDelay: 1,
              repeatType: 'reverse',
            }}
          >
            <p className="mx-auto text-center italic text-gray-500">
              {translate('common.loading')}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatDelay: 1,
              repeatType: 'reverse',
              delay: 10,
            }}
          >
            <p className="mx-auto text-center italic text-gray-500">
              {translate('common.loading-unexpected-time')}
            </p>
          </motion.div>
        </div>
      </motion.div>
    </>
  )
}

function LoadingIcon(props: ComponentProps<'svg'>) {
  return (
    <svg {...props} fill="none" viewBox="-85 -85 300 300" stroke="currentColor" strokeWidth={8}>
      <motion.path
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          repeatType: 'reverse',
          type: 'tween',
          ease: 'easeOut',
          repeatDelay: 1,
        }}
        strokeLinejoin="round"
        transform="translate(-34.105678,-43.054836)"
        d="M 21.262341,38.633676 C 54.247153,-0.49616353 117.89662,-6.0903761 158.83597,23.514839 c 25.67927,20.352265 35.08481,55.104639 33.14437,86.725871 -1.09729,13.80471 -3.89346,28.11375 -11.14569,40.01212 -12.95296,12.928 -36.90626,4.05174 -42.32267,-12.2994 -7.63418,-21.67411 4.01827,-45.418248 -5.15087,-66.900879 -6.51424,-18.412765 -29.42443,-26.949476 -47.023953,-19.529884 -17.346274,5.796701 -25.180969,24.355289 -27.537196,41.11647 -2.699672,19.880563 1.324786,43.827173 19.311598,55.593303 11.634891,7.79662 26.161321,3.00572 39.082041,5.88534 12.45762,1.35302 25.97196,10.2657 25.77098,24.03675 0.65899,9.52885 -5.85221,19.50606 -15.80589,20.53942 -20.36225,4.76149 -41.944107,2.64527 -61.938232,-2.80737 C 37.038395,186.85612 12.675364,163.50261 6.893765,133.87099 -1.6497327,102.00357 2.8256927,66.292156 21.262341,38.633676 Z"
      />
    </svg>
  )
}
