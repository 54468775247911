import useGetEndCustomerRebateCodesForOrder from '@/hooks/customer/useGetEndCustomerRebateCodesForOrder.tsx'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { PublicRebateCodeEntity } from '../../../qourses-api-client'

export function CustomerOrderRebateCodeSelection(props: {
  selectedRebateCode: PublicRebateCodeEntity
  setSelectedRebateCode: Dispatch<SetStateAction<PublicRebateCodeEntity>>
  disabled: boolean
  orderId: string
  loading?: boolean
}) {
  //const { t: translate } = useTranslation()
  const { rebateCodes, isLoading, isError } = useGetEndCustomerRebateCodesForOrder(props.orderId)

  // const calculateUsageForOrder = useCallback(
  //   (multipass: MultipassEntity) => {
  //     const bookingsForMultipass =
  //       !isLoadingBookings && bookings.filter((booking) => booking.multipassId === multipass.id)
  //     return bookingsForMultipass.length
  //   },
  //   [isLoadingBookings, bookings],
  // )

  const [RebateCodeJson, setRebateCodeJson] = useState<string | null>(null)

  useEffect(() => {
    if (RebateCodeJson) {
      const RebateCode = JSON.parse(RebateCodeJson).value as PublicRebateCodeEntity
      props.setSelectedRebateCode(RebateCode)
    }
  }, [RebateCodeJson])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue>
            <Skeleton className="h-4 w-24" />
          </SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedRebateCode = rebateCodes.map((rebateCode) => ({
    value: rebateCode,
    label: rebateCode.id,
  }))

  return (
    <AnimatePresence>
      <Select
        onValueChange={(value) => setRebateCodeJson(value)}
        value={RebateCodeJson ?? undefined}
        disabled={props.disabled || rebateCodes.length === 0}
      >
        <SelectTrigger className="bg-white text-xs">
          <SelectValue placeholder="Rabattcode auswählen">
            <div className="flex items-center justify-between gap-x-3">
              <div className="flex flex-col items-start">
                {props.selectedRebateCode && <>{props.selectedRebateCode.name}</>}
                {props.selectedRebateCode == null && (
                  <div className="flex items-center gap-x-1 justify-center">
                    <p className="text-muted-foreground">Rabattcode auswählen</p>
                    <motion.div
                      key={'loading-rebatecode-select-no-selection'}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{
                        opacity: 0,
                        x: -10,
                      }}
                    />
                  </div>
                )}
              </div>
              <div>
                {props.loading && (
                  <motion.div
                    key={'loading-rebatecode-select'}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{
                      opacity: 0,
                      x: -10,
                    }}
                  >
                    <Loader2 className="size-5 animate-spin text-muted-foreground" />
                  </motion.div>
                )}
              </div>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Rabattcode auswählen</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedRebateCode.map((rebateCode) => (
                <SelectItem value={JSON.stringify(rebateCode)}>
                  <div className="flex flex-col text-xs">{rebateCode.value.name}</div>
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </AnimatePresence>
  )
}
