import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { CalendarEntity } from '../../../qourses-api-client'

export default function useGetCustomerCalendars(customerId: string): {
  calendars: CalendarEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: calendars,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['customer', customerId, 'calendars'],
    async () => {
      const calendars = await qoursesApi.calendarCustomer.calendarCustomerControllerGetCalendars()

      return calendars.filter((calendar) => !calendar.archived)
    },
    {
      enabled: !!customerId,
    },
  )

  let isEmpty = true

  if (calendars === undefined) {
    return { calendars: [], isLoading, isEmpty, isError, refetch }
  }

  if (calendars.length > 0) {
    isEmpty = false
  }

  return { calendars, isLoading, isEmpty, isError, refetch }
}
