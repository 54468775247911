/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEntity } from '../models/CalendarEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns CalendarEntity Create a Calendar
     * @throws ApiError
     */
    public calendarCustomerControllerCreateCalendar(): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/calendar',
        });
    }
    /**
     * @returns CalendarEntity all calendars have been returned
     * @throws ApiError
     */
    public calendarCustomerControllerGetCalendars(): CancelablePromise<Array<CalendarEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/calendar',
        });
    }
    /**
     * @param calendarId
     * @returns CalendarEntity The calendar has been returned
     * @throws ApiError
     */
    public calendarCustomerControllerGetCalendar(
        calendarId: string,
    ): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/calendar/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
        });
    }
    /**
     * @param calendarId
     * @returns CalendarEntity The archived calendar has been returned
     * @throws ApiError
     */
    public calendarCustomerControllerArchiveCalendar(
        calendarId: string,
    ): CancelablePromise<CalendarEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/calendar/{calendarId}/archive',
            path: {
                'calendarId': calendarId,
            },
        });
    }
}
