import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { RebateCodeEntity } from '../../../qourses-api-client'

export default function useGetCoupon(rebateCodeId: string): {
  coupon: RebateCodeEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: coupon,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getCouponQueryKey(rebateCodeId), async () => {
    return qoursesApi.rebate.rebateControllerGetRebateCode(rebateCodeId)
  })

  return { coupon, isLoading, isError, refetch, isRefetching }
}
export const getCouponQueryKey = (rebateCodeId: string) => ['coupon', rebateCodeId]
