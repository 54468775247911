/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostDismissHelpCardDto = {
    helpCard: PostDismissHelpCardDto.helpCard;
    status: boolean;
};
export namespace PostDismissHelpCardDto {
    export enum helpCard {
        COURSES = 'COURSES',
        MEETINGS = 'MEETINGS',
        CUSTOMERS = 'CUSTOMERS',
        MULTIPASSES = 'MULTIPASSES',
        ORDERS = 'ORDERS',
        REBATECODES = 'REBATECODES',
    }
}

