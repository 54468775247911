/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostCalendarDto } from '../models/PostCalendarDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns any
     * @throws ApiError
     */
    public calendarControllerCreateCalendar(
        requestBody: PostCalendarDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/calendar',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns any
     * @throws ApiError
     */
    public calendarControllerGetCalendars(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/calendar',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * @param calendarId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns any
     * @throws ApiError
     */
    public calendarControllerGetCalendar(
        calendarId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/calendar/{calendarId}',
            path: {
                'calendarId': calendarId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * @param calendarId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns any
     * @throws ApiError
     */
    public calendarControllerArchiveCalendar(
        calendarId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/calendar/{calendarId}/archive',
            path: {
                'calendarId': calendarId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
}
