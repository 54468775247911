/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from '../models/BookingEntity';
import type { InstructorEntity } from '../models/InstructorEntity';
import type { MeetingEntity } from '../models/MeetingEntity';
import type { PostGroupedMeetingDto } from '../models/PostGroupedMeetingDto';
import type { PostUngroupedMeetingDto } from '../models/PostUngroupedMeetingDto';
import type { UpdateUngroupedMeetingDto } from '../models/UpdateUngroupedMeetingDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MeetingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a Meeting
     * @param meetingId
     * @returns MeetingEntity Meeting has been returned
     * @throws ApiError
     */
    public meetingControllerGetMeeting(
        meetingId: string,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting/{meetingId}',
            path: {
                'meetingId': meetingId,
            },
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Archive a Meeting
     * @param meetingId
     * @returns MeetingEntity Meeting has been archived
     * @throws ApiError
     */
    public meetingControllerArchiveMeeting(
        meetingId: string,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/meeting/{meetingId}',
            path: {
                'meetingId': meetingId,
            },
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Update a Meetings start, duration, attendees or price override
     * @param meetingId
     * @param requestBody
     * @returns MeetingEntity Meeting has been updated
     * @throws ApiError
     */
    public meetingControllerUpdateMeeting(
        meetingId: string,
        requestBody: UpdateUngroupedMeetingDto,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/meeting/{meetingId}',
            path: {
                'meetingId': meetingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Get Instructors of a Meeting
     * @param meetingId
     * @returns InstructorEntity Meeting Instructors have been returned
     * @throws ApiError
     */
    public meetingControllerGetMeetingInstructors(
        meetingId: string,
    ): CancelablePromise<Array<InstructorEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting/{meetingId}/instructors',
            path: {
                'meetingId': meetingId,
            },
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Get Meetings
     * @param instructorId
     * @param courseId
     * @param courseGroupId
     * @param startDate
     * @param endDate
     * @param archived
     * @param cancelled
     * @param past
     * @returns MeetingEntity Meetings have been returned
     * @throws ApiError
     */
    public meetingControllerGetMeetings(
        instructorId?: string,
        courseId?: string,
        courseGroupId?: string,
        startDate?: string,
        endDate?: string,
        archived?: boolean,
        cancelled?: boolean,
        past?: boolean,
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting',
            query: {
                'instructorId': instructorId,
                'courseId': courseId,
                'courseGroupId': courseGroupId,
                'startDate': startDate,
                'endDate': endDate,
                'archived': archived,
                'cancelled': cancelled,
                'past': past,
            },
        });
    }
    /**
     * Get Meetings sorted from now into the future with past meetings appended
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns MeetingEntity Meetings have been returned
     * @throws ApiError
     */
    public meetingControllerGetMeetingsSortedFromNow(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting/fromNow',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get a Meeting's attendance bookings
     * @param meetingId
     * @returns BookingEntity Attendance Bookings of the meeting have been returned
     * @throws ApiError
     */
    public meetingControllerGetMeetingAttendanceBookings(
        meetingId: string,
    ): CancelablePromise<Array<BookingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting/{meetingId}/bookings/attendance',
            path: {
                'meetingId': meetingId,
            },
        });
    }
    /**
     * Get a meeting's active bookings amount
     * @param meetingId
     * @returns number Active Bookings of the meeting have been returned
     * @throws ApiError
     */
    public meetingControllerGetMeetingActiveBookingsCount(
        meetingId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/meeting/{meetingId}/bookings/active/count',
            path: {
                'meetingId': meetingId,
            },
        });
    }
    /**
     * Create a Meeting for a Course
     * @param requestBody
     * @returns MeetingEntity Course Meeting has been created
     * @throws ApiError
     */
    public meetingControllerCreateCourseMeeting(
        requestBody: PostUngroupedMeetingDto,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/meeting/ungrouped',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Cancel a Meeting
     * @param meetingId
     * @param notifyCustomers
     * @returns MeetingEntity Meeting has been cancelled
     * @throws ApiError
     */
    public meetingControllerCancelMeeting(
        meetingId: string,
        notifyCustomers: boolean,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/meeting/{meetingId}/cancel',
            path: {
                'meetingId': meetingId,
            },
            query: {
                'notifyCustomers': notifyCustomers,
            },
            errors: {
                400: `Meeting has already been cancelled`,
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Restore a Meeting
     * @param meetingId
     * @returns MeetingEntity Meeting has been restored
     * @throws ApiError
     */
    public meetingControllerRestoreMeeting(
        meetingId: string,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/meeting/{meetingId}/restore',
            path: {
                'meetingId': meetingId,
            },
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
    /**
     * Create a Meeting for a Course Group
     * @param requestBody
     * @returns MeetingEntity Course Meeting has been created
     * @throws ApiError
     */
    public meetingControllerCreateCourseGroupMeeting(
        requestBody: PostGroupedMeetingDto,
    ): CancelablePromise<MeetingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/meeting/grouped',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
}
