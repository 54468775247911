import useGetInstructors from '@/hooks/instructors/useGetInstructors.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { Undo2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InstructorEntity } from '../../../qourses-api-client'

export function InstructorSelection(props: {
  setSelectedInstructor: (value: InstructorEntity) => void
  initiallySelectedInstructorId?: string
  disabled?: boolean
}) {
  const { t: translate } = useTranslation()
  const { instructors, isLoading, isError, isEmpty } = useGetInstructors()
  const [instructorJson, setInstructorJson] = useState<string | null>(null)
  const [key, setKey] = useState<number>(+new Date())

  // Set the initially selected instructor if it is defined
  useEffect(() => {
    if (!isLoading && props.initiallySelectedInstructorId !== undefined) {
      const instructor = instructors.find(
        (instructor) => instructor.id === props.initiallySelectedInstructorId,
      )
      if (instructor) {
        setInstructorJson(JSON.stringify(instructor))
      }
    }
  }, [isLoading, props.initiallySelectedInstructorId])

  useEffect(() => {
    if (instructorJson) {
      const instructor = JSON.parse(instructorJson) as InstructorEntity
      props.setSelectedInstructor(instructor)
    }
  }, [instructorJson])

  if (isEmpty) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.instructors.empty')}></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <div className="flex flex-row">
      <Select
        key={key}
        onValueChange={(value) => setInstructorJson(value)}
        value={instructorJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white">
          <SelectValue placeholder={translate('dropdowns.instructors.placeholder')}></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.instructors.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              instructors.map((instructor) => (
                <SelectItem value={JSON.stringify(instructor)}>
                  {instructor.firstName} {instructor.lastName}
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button
        disabled={!instructorJson}
        className="ml-1 bg-gray-200 hover:bg-gray-100"
        variant="secondary"
        onClick={(e) => {
          e.stopPropagation()
          setInstructorJson(undefined)
          // Radix :)
          setKey(+new Date())
        }}
        size="icon"
      >
        <Undo2 className="size-4" />
      </Button>
    </div>
  )
}
