/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PatchRebateCodeDto = {
    /**
     * Rebate code name being displayed to Customers
     */
    name: string;
    /**
     * Rebate code type
     */
    type: PatchRebateCodeDto.type;
    /**
     * Value in mills (Meaning depends on scope; EUR/Permillage)
     */
    valueInMills: number;
    /**
     * Rebate code scope
     */
    scope: PatchRebateCodeDto.scope;
    /**
     * Date from which the Rebate Code is active
     */
    validFrom: string;
    /**
     * Date until the Rebate Code is active. Null means it is infinitely active.
     */
    validUntil?: string;
    /**
     * Protected Rebate Codes require Customers to have been issued the Rebate Code
     */
    protected: boolean;
    /**
     * The minimum total price of the Order the Rebate Code is in for it to be active
     */
    minimumSpendingInMills?: number;
    /**
     * Maximum usages
     */
    maxUsages?: number;
    /**
     * Maximum usages per Customer
     */
    maxUsagesPerCustomer?: number;
    /**
     * Only registered Customers can use this Rebate Code
     */
    registeredUsersOnly?: boolean;
    /**
     * Only registered Customers with a verified phone number can use this Rebate Code
     */
    verifiedPhoneRequired?: boolean;
    /**
     * Customer note being displayed to the Customer
     */
    customerNote?: string;
    /**
     * Internal note being displayed in the backoffice
     */
    internalNote?: string;
    /**
     * Whether the Rebate Code can be combined with one of the opposite scope
     */
    canBeCombined?: boolean;
    /**
     * Whether the Rebate Code is eligible for all bookables of the Organization
     */
    organizationEligibility?: boolean;
    /**
     * Stripe Payment Profile IDs the Rebate Code is eligible for
     */
    stripePaymentProfileIds?: Array<string>;
    /**
     * Course IDs the Rebate Code is eligible for
     */
    courseIds?: Array<string>;
    /**
     * Course Group IDs the Rebate Code is eligible for
     */
    courseGroupIds?: Array<string>;
    /**
     * Multipass Product IDs the Rebate Code is eligible for
     */
    multipassProductIds?: Array<string>;
};
export namespace PatchRebateCodeDto {
    /**
     * Rebate code type
     */
    export enum type {
        ABSOLUTE = 'ABSOLUTE',
        PERMILLAGE = 'PERMILLAGE',
    }
    /**
     * Rebate code scope
     */
    export enum scope {
        ORDER = 'ORDER',
        BOOKING = 'BOOKING',
        CHEAPEST_SINGLE_BOOKING = 'CHEAPEST_SINGLE_BOOKING',
        DUPLICATE_BOOKABLE = 'DUPLICATE_BOOKABLE',
    }
}

