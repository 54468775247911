import { useQuery } from '@tanstack/react-query'
import { PublicOrganizationEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetOrganizationPublic(organizationId: string): {
  organization: PublicOrganizationEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: organization,
    isLoading,
    isError,
  } = useQuery(
    ['public', 'organization'],
    async () => {
      if (organizationId === '' || organizationId === undefined || organizationId === null) {
        return {} as PublicOrganizationEntity
      }

      return qoursesApi.organizationPublic.organizationPublicControllerGetPublicOrganization(
        organizationId,
      )
    },
    {
      enabled: organizationId !== '' && organizationId !== undefined && organizationId !== null,
    },
  )

  if (organization === undefined) {
    return { organization: {} as PublicOrganizationEntity, isLoading, isError }
  }

  return { organization, isLoading, isError }
}
