import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { CalendarEntity } from '../../../qourses-api-client'

export default function useGetCalendars(): {
  calendars: CalendarEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: calendars,
    isLoading,
    isError,
    refetch,
  } = useQuery(['calendars'], async () => {
    const calendars = await qoursesApi.calendar.calendarControllerGetCalendars('false')

    return calendars.filter((calendar: { archived: never }) => !calendar.archived)
  })

  let isEmpty = true

  if (calendars === undefined) {
    return { calendars: [], isLoading, isEmpty, isError, refetch }
  }

  if (calendars.length > 0) {
    isEmpty = false
  }

  return { calendars, isLoading, isEmpty, isError, refetch }
}
