import { useQuery } from '@tanstack/react-query'
import { RebateCodeEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

interface RebateCodeFilters {
  archived?: boolean
  valid?: boolean
  invalid?: boolean
}

export default function useGetCoupons(filters: RebateCodeFilters): {
  coupons: RebateCodeEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: coupons,
    isLoading,
    isError,
  } = useQuery(getCouponsQueryKey(filters), async () => {
    return qoursesApi.rebate.rebateControllerGetRebateCodes(
      filters?.archived,
      filters?.valid,
      filters?.invalid,
    )
  })

  let isEmpty = true

  if (coupons === undefined) {
    return { coupons: [], isLoading, isEmpty, isError }
  }

  if (coupons.length > 0) {
    isEmpty = false
  }

  return { coupons, isLoading, isEmpty, isError }
}
export const getCouponsQueryKey = (filters: RebateCodeFilters) => ['coupons', filters]
