import { useQuery } from '@tanstack/react-query'
import { InstructorEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetInstructors(): {
  instructors: InstructorEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: instructors,
    isLoading,
    isError,
  } = useQuery(getInstructorsQueryKey(), async () => {
    return qoursesApi.instructor.instructorControllerGetInstructors()
  })

  let isEmpty = true

  if (instructors === undefined) {
    return { instructors: [], isLoading, isEmpty, isError }
  }

  if (instructors.length > 0) {
    isEmpty = false
  }

  return { instructors, isLoading, isEmpty, isError }
}
export const getInstructorsQueryKey = () => ['instructors']
