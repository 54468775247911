import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { Calendar } from '@/shadcn/components/ui/calendar.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '@/shadcn/components/ui/popover.tsx'
import { TimePicker } from '@/shadcn/components/ui/time-picker-hours-and-minutes.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { de, enUS } from 'date-fns/locale'
import i18n from 'i18next'
import { CalendarIcon, Undo2 } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Matcher } from 'react-day-picker'

export function DateSelect(props: {
  startDate: DateTime
  setStartDate: (date: DateTime) => void
  title: string
  placeholder: string
  label: string
  optional?: boolean
  calendarDisabledMatcher?: Matcher | Matcher[]
  focusMonth?: DateTime
  disabled?: boolean
  withTimePicker?: boolean
  withNowButton?: boolean
  nowButtonLabel?: string
}) {
  const locale = i18n.language.includes('de') ? de : enUS

  const [pickedTime, setPickedTime] = useState<Date>()

  useEffect(() => {
    if (!props.withTimePicker || !props.startDate) {
      return
    }
    const dateWithTime = props.startDate.set({
      hour: pickedTime?.getHours() || 0,
      minute: pickedTime?.getMinutes() || 0,
    })
    props.setStartDate(dateWithTime)
  }, [pickedTime])

  useEffect(() => {
    if (props.startDate && props.withTimePicker && !pickedTime) {
      setPickedTime(props.startDate.toJSDate())
    }
  }, [props.startDate])

  return (
    <>
      <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
        {props.title}
        {props.optional && (
          <Badge variant="indigoBezel" className="ml-2">
            Optional
          </Badge>
        )}
      </label>

      {props.withTimePicker && (
        <div>
          <TimePicker date={pickedTime} setDate={setPickedTime} />
        </div>
      )}
      <div className="flex items-center gap-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              disabled={props.disabled}
              variant="outline"
              className={cn(
                'w-[280px] justify-between bg-white text-left font-normal',
                !props.startDate && 'text-muted-foreground',
              )}
            >
              {props.startDate ? (
                props.startDate.toLocaleString(DateTime.DATE_HUGE)
              ) : (
                <span>{props.placeholder}</span>
              )}
              <CalendarIcon className="mr-2 h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              defaultMonth={props.focusMonth?.toJSDate()}
              disabled={props.calendarDisabledMatcher || props.disabled}
              mode="single"
              selected={props.startDate?.toJSDate()}
              onSelect={(date) => {
                if (pickedTime) {
                  const dateWithTime = DateTime.fromJSDate(date).set({
                    hour: pickedTime.getHours(),
                    minute: pickedTime.getMinutes(),
                  })
                  props.setStartDate(dateWithTime)
                  return
                }
                props.setStartDate(date ? DateTime.fromJSDate(date) : null)
              }}
              initialFocus
              locale={locale}
            />
          </PopoverContent>
        </Popover>
        {props.startDate && (
          <Button
            disabled={props.disabled}
            className="ml-1 bg-gray-200 hover:bg-gray-100"
            variant="secondary"
            onClick={() => {
              props.setStartDate(undefined)
              if (props.withTimePicker) {
                setPickedTime(undefined)
              }
            }}
            size="icon"
          >
            <Undo2 className="size-4" />
          </Button>
        )}
        {!props.startDate && props.withNowButton && (
          <Button
            disabled={props.disabled}
            className="ml-1 bg-gray-200 hover:bg-gray-100"
            variant="outline"
            onClick={() => {
              props.setStartDate(DateTime.now())
              if (props.withTimePicker) {
                setPickedTime(new Date())
              }
            }}
          >
            {props.nowButtonLabel}
          </Button>
        )}
      </div>
      <p className="mt-1 text-sm text-muted-foreground">{props.label}</p>
    </>
  )
}
