import { useQuery } from '@tanstack/react-query'
import { PublicLocationEntity } from '../../../qourses-api-client'
import { qoursesApi } from '../../api/qourses.tsx'

export default function useGetLocationPublic(locationId: string): {
  location: PublicLocationEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: location,
    isLoading,
    isError,
  } = useQuery(['public', 'location', locationId], async () => {
    return qoursesApi.locationPublic.locationPublicControllerGetLocation(locationId)
  })

  if (location === undefined) {
    return { location: {} as PublicLocationEntity, isLoading, isError }
  }

  return { location, isLoading, isError }
}
