/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostCalendarDto = {
    instructorId?: string;
    privilege?: PostCalendarDto.privilege;
};
export namespace PostCalendarDto {
    export enum privilege {
        FULL = 'FULL',
        LIMITED = 'LIMITED',
        MINIMAL = 'MINIMAL',
    }
}

