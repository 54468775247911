/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CalendarEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    /**
     * The token giving access to the ICS endpoint
     */
    token: string;
    type: CalendarEntity.type;
    organizationId: string | null;
    instructorId: string | null;
    orderId: string | null;
    guestId: string | null;
    customerId: string | null;
    archived: boolean;
    privilege: CalendarEntity.privilege;
    lastUsageDay: string | null;
};
export namespace CalendarEntity {
    export enum type {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        GUEST = 'GUEST',
        CUSTOMER = 'CUSTOMER',
        ORDER = 'ORDER',
    }
    export enum privilege {
        FULL = 'FULL',
        LIMITED = 'LIMITED',
        MINIMAL = 'MINIMAL',
    }
}

