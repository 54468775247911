import { qoursesApi } from '@/api/qourses.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import useGetCustomerCalendars from '@/hooks/customer/useGetCustomerCalendars.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { DialogDescription, DialogHeader, DialogTitle } from '@/shadcn/components/ui/dialog.tsx'
import { ToastVariant, sendNotification } from '@/utils.tsx'
import { ChevronRight, Cog } from 'lucide-react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { OrderWithPriceDetailsEntity } from '../../../qourses-api-client'

export default function AddMeetingsToCalendarModal({
  order,
  customerId,
}: {
  order?: OrderWithPriceDetailsEntity
  customerId?: string
}) {
  const { t: translate } = useTranslation()

  const apiUrl = import.meta.env.VITE_API_URL

  const [loading, setLoading] = useState(false)

  const {
    calendars,
    isLoading: isLoadingCalendars,
    isError: isErrorCalendars,
    refetch,
  } = useGetCustomerCalendars(order ? order.customerId : customerId)

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const handleCreateCalendar = async (
    calendarProvider: 'apple' | 'google' | 'outlook' | 'manual',
  ) => {
    setLoading(true)
    let calendar = undefined

    if (order && order.guestId) {
      calendar = await handleCreateGuestCalendar()
    } else {
      calendar = await handleCreateCustomerCalendar()
    }

    const calendarUrl = `${apiUrl}/v1/public/calendar/${calendar.token}/${DateTime.now().plus({ years: 1 }).toISODate()}`

    if (calendarProvider === 'apple') {
      const webcalUrl = calendarUrl.replace('https://', 'webcal://')
      if (isMobile) {
        setTimeout(() => {
          window.open(`${webcalUrl}`, '_blank')
        })
      } else {
        setTimeout(() => {
          window.open(`${webcalUrl}`, 'noopener,noreferrer')
        })
      }
    }

    if (calendarProvider === 'google') {
      const webcalUrl = calendarUrl.replace('https://', 'webcal://')
      setTimeout(() => {
        window.open(`https://calendar.google.com/calendar/r?cid=${webcalUrl}`, '_blank')
      })
    }

    if (calendarProvider === 'outlook') {
      setTimeout(() => {
        window.open(`https://outlook.live.com/calendar/addcalendar?url=${calendarUrl}&name=Qourses`)
      })
    }

    if (calendarProvider === 'manual') {
      navigator.clipboard.writeText(calendarUrl)
      sendNotification(
        translate('modals.addMeetingsToCalendarModal.notifications.manualCopy.title'),
        translate('modals.addMeetingsToCalendarModal.notifications.manualCopy.subtitle'),
        ToastVariant.Info,
      )
    }

    refetch()
    setLoading(false)
  }

  const handleCreateGuestCalendar = async () => {
    try {
      if (!order.guestId) {
        return
      }
      return qoursesApi.calendarPublic.calendarPublicControllerCreateOrderCalendar({
        orderId: order.id,
        guestId: order.guestId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreateCustomerCalendar = async () => {
    try {
      return qoursesApi.calendarCustomer.calendarCustomerControllerCreateCalendar()
    } catch (error) {
      console.error(error)
    }
  }

  const handleArchiveCalendar = async (calendarId: string) => {
    try {
      await qoursesApi.calendarCustomer.calendarCustomerControllerArchiveCalendar(calendarId)
      refetch()
      return
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogHeader className="mb-2">
          <DialogTitle className="flex flex-col gap-y-2 sm:mt-2">
            <p className="mt-4">{translate('modals.addMeetingsToCalendarModal.title')}</p>
          </DialogTitle>
          <DialogDescription>
            {translate('modals.addMeetingsToCalendarModal.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </DialogHeader>
      {calendars.length > 0 && (
        <div className="mb-4 relative bg-gray-50 p-2 ring-1 ring-gray-200 rounded-lg shadow-inner max-h-32 overflow-y-auto">
          {!isLoadingCalendars &&
            !isErrorCalendars &&
            calendars.map((calendar) => (
              <>
                <div className="mt-2 bg-white text-sm flex gap-x-1 p-2 border rounded-md items-center justify-between first:mt-2 last:mb-2">
                  <div>
                    <p>{translate('modals.addMeetingsToCalendarModal.lastSynced')}</p>
                    <p className="font-semibold">
                      {calendar.lastUsageDay !== null
                        ? DateTime.fromISO(calendar.lastUsageDay).toLocaleString(DateTime.DATE_FULL)
                        : translate('modals.addMeetingsToCalendarModal.syncPending')}
                    </p>
                  </div>
                  <div
                    className="cursor-pointer text-xs underline mt-1 flex items-center"
                    onClick={() => handleArchiveCalendar(calendar.id)}
                  >
                    {translate('modals.addMeetingsToCalendarModal.archiveCalendar')}
                    <ChevronRight className="ml-1 size-3" />
                  </div>
                </div>
              </>
            ))}
        </div>
      )}

      <div className="relative mb-2">
        <div aria-hidden="true" className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 font-semibold text-muted-foreground">
            {translate('modals.addMeetingsToCalendarModal.chooseCalendarTitle')}
          </span>
        </div>
      </div>
      <Button
        variant="outline"
        className="mt-3 sm:mt-0"
        onClick={() => handleCreateCalendar('apple')}
        disabled={loading}
      >
        <img className="inline-block size-6 p-1 mr-1" src="/logos/apple.svg" alt="Apple Logo" />
        {translate('modals.addMeetingsToCalendarModal.buttonApple')}
      </Button>
      <Button
        variant="outline"
        className="mt-3 sm:mt-0"
        onClick={() => handleCreateCalendar('google')}
        disabled={loading}
      >
        <img className="inline-block size-6 p-1 mr-1" src="/logos/google.svg" alt="Apple Logo" />
        {translate('modals.addMeetingsToCalendarModal.buttonGoogle')}
      </Button>
      <Button
        variant="outline"
        className="mt-3 mb-2 sm:mt-0 sm:mb-0"
        onClick={() => handleCreateCalendar('outlook')}
        disabled={loading}
      >
        <img className="inline-block size-6 p-1 mr-1" src="/logos/microsoft.svg" alt="Apple Logo" />
        {translate('modals.addMeetingsToCalendarModal.buttonOutlook')}
      </Button>
      <div className="mt-2 border-t-2 border-dashed">
        <Button
          variant="outline"
          className="flex flex-1 w-full mt-3 sm:mt-6"
          onClick={() => handleCreateCalendar('manual')}
        >
          <Cog className="inline-block size-6 p-1 mr-1" />
          {translate('modals.addMeetingsToCalendarModal.buttonManual')}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
