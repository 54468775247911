import { qoursesApi } from '@/api/qourses.tsx'
import { useQuery } from '@tanstack/react-query'
import { PublicRebateCodeWithUsageInfoEntity } from '../../../qourses-api-client'

export default function useGetCouponPublic(
  rebateCodeId: string,
  code?: string,
): {
  coupon: PublicRebateCodeWithUsageInfoEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: coupon,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['coupon', 'public', rebateCodeId], async () => {
    return qoursesApi.rebatePublic.rebatePublicControllerGetRebateCode({
      codeId: code ? undefined : rebateCodeId,
      code: code ? code : undefined,
    })
  })

  return { coupon, isLoading, isError, refetch, isRefetching }
}
